const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    console.log(entry);
    if(entry.isIntersecting) {
      entry.target.classList.add('show');
    } else {
      entry.target.classList.remove('show');
    }
  });
});

const hiddenElements = document.querySelectorAll('.hidden');
hiddenElements.forEach((item) => observer.observe(item));

function onClickMobileMenu() {
  console.log('clic')
  document.getElementById("mobileMenu").classList.toggle("show");
}

const mobileNavIcon = document.getElementById("mobileNavIcon");

mobileNavIcon.addEventListener('click', function onClickMobileMenu() {
  document.getElementById("mobileMenu").classList.toggle("show");
});
